import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addDunningMaster',
  props: ['dunningSetupId'],
  data() {
    return {
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      editMode: false,
      active: false,
      parent_value_set_id: null,
      showValueSetModal: false,
      showAddDunningMasterModal: false,
      legalEntity: {
        text: null,
        value: null
      },
      operatingUnit: {
        text: null,
        value: null
      },
      setupType: {
        value: null,
        text: null
      },
      setupSubType: {
        value: null,
        text: null
      },
      setupValue: {
        value: null,
        text: null
      },
      payload: null,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      setTimeVsetCode: null,
      vsetCode:null,
      defaultValue:{
        value: null,
        text: null
      }
    };
  },
  mounted() {
    if (this.dunningSetupId) {
      this.getDunningCommonSetupDataById(this.dunningSetupId);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addUpdateCommonSetupData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getDunningCommonSetupDataById(dunningSetupId) {
      this.loader = true;
      this.$store
        .dispatch('dunningAdmin/getDunningCommonSetupDataById', dunningSetupId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.legalEntity.text = result.legal_entity_name;
            this.legalEntity.value = result.le_id;
            this.operatingUnit.text = result.ou_name;
            this.operatingUnit.value = result.org_id;
            this.setupType.text = result.setup_type;
            this.setupType.value = result.setup_type;
            this.setupSubType.value = result.setup_sub_type;
            this.setupSubType.text = result.setup_sub_type;
            this.setupValue.text = result.setup_value;
            this.setupValue.value = result.setup_value_id;
            this.active = result.is_activated === 'Y' ? true : false;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addUpdateCommonSetupData() {
      const payload = {
        dunning_setup_id: this.dunningSetupId,
        le_id: this.legalEntity.value,
        org_id: this.operatingUnit.value,
        setup_type: this.setupType.value,
        setup_sub_type: this.setupSubType.value,
        setup_value: this.setupValue.text,
        setup_value_id: this.setupSubType.value === 'DUNNING_LETTER_TRANSACTION_TYPE' ? null : this.setupValue.value, 
        is_activated: this.active ? 'Y' : 'N',
    
      };
      this.loader = true;
      this.$store
        .dispatch('dunningAdmin/addUpdateCommonSetupData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },

    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.operatingUnit = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.text = item.value_code;
        this.operatingUnit.value = item.value_set_dtl_id;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_SETUP_TYPE) {
        this.setupType = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.setupTypeDtlId = item.value_set_dtl_id;
        this.setupSubType = {
          value: null,
          text: null
        };
        this.setupValue = {
          value: null,
          text: null
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.DUNNING_SETUP_SUB_TYPE
      ) {
        this.setupSubType.text = item.value_meaning;
        this.setupSubType.value = item.value_code;
      } else if (this.vsetCode === this.setupSubType.value) {
        this.setupValue.text = item.value_meaning
          ? item.value_meaning
          : item.value_code;
        this.setupValue.value = item.value_meaning
          ? item.value_code
          : item.value_set_dtl_id;
      }
    },

    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_SETUP_SUB_TYPE) {
        this.parent_value_set_id = this.setupTypeDtlId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },

    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode){
      switch(vsetCode){
        case appStrings.VALUESETTYPE.DUNNING_SETUP_TYPE:
          this.setupType = this.defaultValue
          break;
        case appStrings.VALUESETTYPE.DUNNING_SETUP_SUB_TYPE:
          this.setupSubType = this.defaultValue
          break;
        case 'setupValue':
          this.setupValue = this.defaultValue
          break;
        }
      }
    
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
