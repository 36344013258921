import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import addDunningMaster from './addDunningMaster';
export default {
  name: 'DunningSetup',
  watch: {
    currentPage: function() {
      this.getDunningCommonSetupList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getDunningCommonSetupList();
    }
  },
  components: {
    addDunningMaster
  },
  data() {
    return {
      active: true,
      setupType: {
        value: null,
        text: null
      },
      setupSubType: {
        value: null,
        text: null
      },
      setupValue: {
        value: null,
        text: null
      },
      parent_value_set_id: null,
      showValueSetModal: false,
      showAddDunningMasterModal: false,
      payload: null,
      showChildModal: false,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      setTimeVsetCode: null,
      vsetCode: null,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      periodType: null,
      OperatingUnitList: [
        {
          value: '2A',
          text: '2A'
        },
        {
          value: '2B',
          text: '2B'
        }
      ],
      dunningSetupData: [],
      dunningSetupFields: [
        {
          key: 'legal_entity_name',
          label: 'Legal Entity',
          class: 'd-none'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit',
          class: 'd-none'
        },
        {
          key: 'setup_type'
        },
        {
          key: 'setup_sub_type'
        },
        {
          key: 'setup_value'
        },
        {
          key: 'is_activated',
          label: 'Active'
        }
      ],
      dunningSetupId: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddDunningMasterModal = true;
          this.dunningSetupId = null;
        }
      }
    });
  },
  methods: {
    getDunningCommonSetupList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.legalEntity.value,
        org_id: this.operatingUnit.value,
        setup_type: this.setupType.value,
        setup_sub_type: this.setupSubType.value
      };
      this.loader = true;
      this.$store
        .dispatch('dunningAdmin/getDunningCommonSetupList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.dunningSetupData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.operatingUnit = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit.text = item.value_code;
        this.operatingUnit.value = item.value_set_dtl_id;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_SETUP_TYPE) {
        this.setupType = {
          value: item.value_code,
          text: item.value_meaning
        };
        this.setupTypeDtlId = item.value_set_dtl_id;
        this.setupSubType = {
          value: null,
          text: null
        };
        this.setupValue = {
          value: null,
          text: null
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.DUNNING_SETUP_SUB_TYPE
      ) {
        this.setupSubType.text = item.value_meaning;
        this.setupSubType.value = item.value_code;
      } else if (this.vsetCode === this.setupSubType.value) {
        this.setupValue.text = item.value_code;
        this.setupValue.value = item.value_set_dtl_id;
      }
    },

    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_SETUP_SUB_TYPE) {
        this.parent_value_set_id = this.setupTypeDtlId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },

    closeValueSetModal() {
      this.showValueSetModal = false;
    },

    clear() {
      this.legalEntity = {
        value: null,
        text: null
      };
      this.operatingUnit = {
        value: null,
        text: null
      };

      this.setupType = {
        value: null,
        text: null
      };
      this.setupSubType = {
        value: null,
        text: null
      };
      this.setupValue = {
        value: null,
        text: null
      };
      this.totalRows = null;
      this.dunningSetupData = [];
      this.parent_value_set_id = null;
      this.vsetCode = null;
    },
    rowSelected(item) {
      this.dunningSetupId = item.dunning_setup_id;
      this.showHideDunningMasterModal(true);
    },
    showHideDunningMasterModal(flag) {
      this.showAddDunningMasterModal = flag;
      if (!flag) {
        this.getDunningCommonSetupList();
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.setupType.value) {
        this.setupType = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.setupSubType.value) {
        this.setupSubType = {
          text: null,
          value: null
        };
      }else if (vsetCode === this.setupValue.value){
        this.setupValue = {
          text: null,
          value: null

        }

      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
